import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000,
      retry: 1,
      refetchOnMount: 'always',
    },
  },
});

function ReactQueryConfiguration({ children }: ReactQueryConfigurationProps) {
  return (
    <QueryClientProvider client={client}>
      {children}
      {/* for debug */
      /*<ReactQueryDevtools initialIsOpen={false} />*/}
    </QueryClientProvider>
  );
}

interface ReactQueryConfigurationProps {
  children: React.ReactNode;
}

export default ReactQueryConfiguration;
