import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

export function UnAuthRouter() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<div>login</div>}/>
        <Route path="*" element={<Navigate to="/login"/>}/>
      </Routes>
    </BrowserRouter>
  )
}
