import React from 'react';
import {Header} from 'antd/es/layout/layout';
import {Col, Row, Switch, Typography} from 'antd';

import {LanguageContext} from '@app/global_provider';
import {i18n} from '@app/translations/i18n.config';
import {Languages} from '@app/types/languages';

export const AppHeader = () => {
  const {language, setLanguage} = React.useContext<any>(LanguageContext);

  const languageChangeHandler = (isActive: boolean) => {
    const activeLanguage = isActive ? Languages.AR : Languages.EN;

    setLanguage(activeLanguage);
    i18n.locale = activeLanguage;
    localStorage.setItem('lang', activeLanguage);
  }

  return (
    <Header className={'py-2 px-4 mx-2 mb-2'}>
      <Row className={'justify-content-between align-items-center'}>
        <Col span={12}>
          <Typography.Title level={2}>Dashboard</Typography.Title>
        </Col>
        <Col span={1}>
          <Switch
            onChange={languageChangeHandler}
            checkedChildren={Languages.AR}
            unCheckedChildren={Languages.EN}
            checked={language === Languages.AR}
          />
        </Col>
      </Row>
    </Header>
  );
};
