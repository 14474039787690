import React, {useState} from 'react';
import {Layout, Menu} from 'antd';
import Sider from "antd/es/layout/Sider";
import {useNavigate} from "react-router-dom";

import {Logo} from "@app/components/logo";
import {AppHeader} from '@app/components/app_header';

import {ReactComponent as DashboardIcon} from "@app/assets/icons/dashboard.svg";
import {ReactComponent as Arrow} from "@app/assets/icons/left_arrow.svg";
import {UserContainer} from "@app/components/user/user_container";

export const AuthLayout = ({children}: any) => {
  const navigate = useNavigate();

  const [isCollapsed, setIsCollapsed] = useState(true)

  const items = [
    {
      path: '/',
      label: 'Dashboard',
      key: '1',
      icon: <DashboardIcon/>,
    },
    // {
    //   path: '/list',
    //   label: 'Refund List',
    //   key: '2',
    //   icon: <ListIcon/>,
    //   selectable: false
    // }
  ];

  const handleNavigation = (key: string) => {
    const {path} = items.find(item => item.key === key)
    navigate(path)
  }

  return (
    <Layout>
      <Sider
        className={'py-4 px-3'}
        width={'270px'}
        collapsedWidth={'72px'}
        collapsed={isCollapsed}
        collapsible
        reverseArrow
        defaultCollapsed
        onCollapse={() => setIsCollapsed((prev) => !prev)}
        trigger={<Arrow/>}
      >
        <Logo isCollapsed={isCollapsed}/>
        <Menu
          theme="light"
          mode="inline"
          onClick={(item) => handleNavigation(item.key)} defaultSelectedKeys={['1']}
          items={items}
        />
        <UserContainer isCollapsed={isCollapsed}/>
      </Sider>
      <Layout>
        <AppHeader/>
        {children}
      </Layout>
    </Layout>
  );
};
