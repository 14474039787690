import React, {FC} from 'react';
import {Button, Popconfirm, Space, Typography} from 'antd';

import {i18n} from "@app/translations/i18n.config";
import {ReactComponent as BlockIcon} from "@app/assets/icons/block.svg";

interface ActionButtonProps {
  onSubmit: () => void;
  onSave: () => void;
  onCancel: () => void;
  disableSubmit?: boolean;
}

export const ActionButton: FC<ActionButtonProps> = ({onSubmit, onSave, onCancel, disableSubmit}) => {

  return (
    <Space size={'middle'}>
      <Button
        type={'default'}
        onClick={onSave}>
        {i18n.t('buttons.saveAsDraft')}
      </Button>
      <Button
        disabled={disableSubmit}
        onClick={onSubmit}
        type={'primary'}>
        {i18n.t('buttons.submit')}
      </Button>
      <Popconfirm
        title={
          <>
            <Typography.Title level={4}>{i18n.t('popconfirm.cancelRequestTitle')}</Typography.Title>
            <p>{i18n.t('popconfirm.cancelRequestDescription')}</p>
          </>
        }
        onConfirm={onCancel}
        icon={null}
        okText={i18n.t('popconfirm.yes')}
        cancelText={i18n.t('popconfirm.no')}
        placement={'bottom'}
      >
        <Button icon={<BlockIcon/>} type={'ghost'}>{i18n.t('buttons.cancel')}</Button>
      </Popconfirm>
    </Space>
  );
};
