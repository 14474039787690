export class AuthSessionStorage {
  static storeCreds(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  static getCreds(key: string) {
    return sessionStorage.getItem(key);
  }

  static resetCreds(key: string) {
    return sessionStorage.removeItem(key);
  }
}
