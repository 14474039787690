import React from 'react';
import {Button, Col, Row, Space, Tooltip, Typography} from 'antd';

import {ReactComponent as LogOutIcon} from '@app/assets/icons/log_out.svg';
import {useAuth} from '@app/auth/auth-context';
import {UserAvatar} from "@app/components/user/user_avatar";
import {Logger} from "@app/utils/logger/logger-service";
import {i18n} from "@app/translations/i18n.config";

export const UserContainer = ({isCollapsed}) => {
  const {onLogout, user} = useAuth();

  const handleLogOut = () => {
    Logger.info('Logout');
    onLogout();
  };


  return (
    <Space
      direction={'vertical'}
      size={null}
      className={`light-grey-bg border-radius-large p-2 position-absolute bottom-0 start-0 end-0 ${isCollapsed ? 'd-flex flex-column align-items-center' : ''}`}
      style={{margin: isCollapsed ? '0 -0.5rem' : '0'}}
    >
      <Row className={'d-flex align-items-center flex-sm-nowrap mb-1'}>
        <Col>
          <UserAvatar title={user.name} isCollapsed={isCollapsed} tooltip/>
        </Col>
        <Col className={`mx-2 ${isCollapsed && 'position-absolute'}`}>
          <Typography.Title level={4} className={isCollapsed && 'collapsed'}>
            {user.name}
          </Typography.Title>
        </Col>
      </Row>
      <Tooltip placement={'left'} title={i18n.t('buttons.logout')} zIndex={isCollapsed ? 5 : -1}>
        <Button
          className={`log-out ${isCollapsed && 'collapsed'}`}
          type={'dashed'}
          icon={<LogOutIcon/>}
          onClick={handleLogOut}
        >
          {!isCollapsed && i18n.t('buttons.logout')}
        </Button>
      </Tooltip>
    </Space>
  );
};
