import axiosInstance from "@app/config/api/config";

export async function createRefundRequest(payload: object) {
  const res = await axiosInstance.post('refund_requests', payload);
  return res.data;
}

export async function getRefundRequests() {
  const res = await axiosInstance.get('refund_requests');
  return res.data;
}

export async function getRefundRequest(id: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(id)}`);
  return res.data;
}

export async function updateRefundRequest(id: string, payload: any) {
  const res = await axiosInstance.put(`refund_requests/${encodeURIComponent(id)}`, payload);
  return res.data;
}

export async function deleteRefundRequest(id: string) {
  const res = await axiosInstance.delete(`refund_requests/${encodeURIComponent(id)}`);
  return res.data;
}
