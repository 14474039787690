import React, {useState} from 'react';
import {Button, Col, Row, Segmented, Space} from 'antd';

import {Content} from "antd/es/layout/layout";
import {RequestTable} from "@app/components/tables/request_table";
import {RequestModal} from "@app/components/modals/request_modal";
import {i18n} from "@app/translations/i18n.config";

import {ReactComponent as PlusIcon} from '@app/assets/icons/plus.svg';

export const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrafts, setIsDrafts] = useState(true);

  return (
    <Content className={'mx-2 p-4'}>
      <Row className={'h-100'}>
        <Col span={24} className={'h-100 mb-3'}>
          <Space className={'w-100 justify-content-between'}>
            <Segmented
              defaultValue={i18n.t('request.tabs.drafts')}
              onChange={(v) => v === i18n.t('request.tabs.drafts') ? setIsDrafts(true) : setIsDrafts(false)}
              options={[i18n.t('request.tabs.requests'), i18n.t('request.tabs.drafts')]}/>
            <Button icon={<PlusIcon/>} size={'small'} type={'default'} onClick={() => setIsModalOpen(true)}>
              {i18n.t('request.new')}
            </Button>
          </Space>
          <Content className={'sub-content-white p-2 rounded-top-0'} style={{minHeight: '50%'}}>
            <RequestTable isDrafts={isDrafts}/>
          </Content>
        </Col>
        <RequestModal open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)}/>
      </Row>
    </Content>
  );
};
