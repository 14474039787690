import React from 'react';
import {Button, Comment, Space, Typography} from "antd";

import {i18n} from "@app/translations/i18n.config";
import {UserAvatar} from "@app/components/user/user_avatar";

import {ReactComponent as PlusIcon} from "@app/assets/icons/plus.svg";

export const Comments = () => {
  return (
    <Space direction={'vertical'} className={'w-100 h-100 light-grey-bg border-radius-large p-2'}>
      <Space className={'w-100 justify-content-between p-2 mb-2'}>
        <Typography.Title level={3}>{i18n.t('comments.title')}</Typography.Title>
        <Button icon={<PlusIcon/>} size={'small'} type={'default'}>{i18n.t('comments.add')}</Button>
      </Space>
      <Comment
        author={'Han Solo'}
        avatar={<UserAvatar title={'Han Solo'}/>}
        content={'comment content'}
        datetime={'29.07.2024 17:13'}
      />
      <Comment
        author={'Han Solo'}
        avatar={<UserAvatar title={'Han Solo'}/>}
        content={'comment content'}
        datetime={'29.07.2024 17:13'}
      />
    </Space>
  );
};
