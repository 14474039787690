import { Env } from '@app/config/env';

export const KeycloakConfig = {
  url: Env.get('SSO_AUTH_URL'),
  realm: Env.get('SSO_AUTH_REALM'),
  clientId: Env.get('SSO_AUTH_CLIENT_ID'),
};

export const KeycloakInitOptions = {
  onLoad: 'login-required',
};
