import React from 'react';
import {Space, Typography} from "antd";
import Dragger from "antd/es/upload/Dragger";

import {i18n} from "@app/translations/i18n.config";

import {ReactComponent as DeleteIcon} from "@app/assets/icons/delete.svg";
import {ReactComponent as DownloadIcon} from "@app/assets/icons/download.svg";

export const Attachments = () => {
  return (
    <Space className={'w-100 mt-4'} direction={'vertical'}>
      <Typography.Title level={3}>{i18n.t('attachments.title')}</Typography.Title>
      <Dragger
        className={'dragger'}
        showUploadList={{removeIcon: () => <DeleteIcon/>}}>
        <p className="ant-upload-drag-icon">
          <DownloadIcon/>
        </p>
        <p className="ant-upload-text">{i18n.t('attachments.description')}</p>
        <p className="ant-upload-hint">{i18n.t('attachments.fileInfo')}</p>
      </Dragger>
    </Space>
  );
};
