import React, {FC} from 'react';
import {Tag} from "antd";

import {RequestStatuses} from "@app/types/statuses";

interface RequestStatusProps {
  status: RequestStatuses;
}

export const RequestStatus: FC<RequestStatusProps> = ({status}) => {
  const getStatusColor = (s) => {
    switch (s) {
      case RequestStatuses.ApprovedByAdmin:
      case RequestStatuses.ApprovedByAuditor:
      case RequestStatuses.ApprovedByPlanningTeam:
        return 'green';
      case RequestStatuses.NotReceived:
      case RequestStatuses.ReturnedForVerification:
        return 'gold';
      case RequestStatuses.InProcess:
        return 'blue'
      case RequestStatuses.ModifyRequest:
        return 'red'
      case RequestStatuses.RefundPaid:
        return 'geekblue'
      case RequestStatuses.Draft:
        return 'volcano'
      case RequestStatuses.Cancelled:
        return 'default'
      default:
        return null
    }
  }
  return (
    <Tag color={getStatusColor(status)}>{status}</Tag>
  );
};
