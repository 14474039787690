import React, { FC } from 'react';
import { HTMLFieldProps } from 'uniforms';
import { Col, Row } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { Space } from 'antd';

type FieldType = {
  field?: any;
  name?: string;
  label?: string;
  multiple?: boolean;
  value?: any;
};
type FileUploaderProps = HTMLFieldProps<string, HTMLDivElement> & FieldType;

// @ts-ignore
export const FilesRepresentor: FC<FileUploaderProps> = ({ onChange, value, ...restProps }) => {
  let files = [];
  try {
    files = JSON.parse(value);
  } catch (error) {
    console.error(error);
  }

  return (
    <div className="py-3">
      {files.map((file) => (
        <Space>
          <Row key={file.name}>
            <Col>
              <PaperClipOutlined />
              <a className="styled-like-a-button" download={file.name} href={file.content}>
                {file.name}
              </a>
            </Col>
          </Row>
        </Space>
      ))}
    </div>
  );
};
