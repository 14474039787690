export enum RequestStatuses {
  Draft = 'Draft',
  NotReceived = 'Not received',
  ReturnedForVerification = 'Returned for Verification',
  ModifyRequest = 'Modify Request',
  Cancelled = 'Cancelled',
  InProcess = 'In Process',
  RefundPaid = 'Refund Paid',
  ApprovedByAdmin = 'Approved by Admin',
  ApprovedByAuditor = 'Approved by Auditor',
  ApprovedByPlanningTeam = 'Approved by Planning Team'
}
