import { AuthSessionStorage } from '@app/auth/auth-session-storage';

export class AuthService {
  static AUTH_TOKEN_KEY = 'auth-token';
  static ID_TOKEN_KEY = 'id-token';
  static REFRESH_TOKEN_KEY = 'refresh-token';

  static signInUser(authToken: string, refreshToken: string, idToken: string) {
    AuthSessionStorage.storeCreds(AuthService.AUTH_TOKEN_KEY, authToken);
    AuthSessionStorage.storeCreds(AuthService.REFRESH_TOKEN_KEY, refreshToken);
    AuthSessionStorage.storeCreds(AuthService.ID_TOKEN_KEY, idToken);
  }

  static signOutUser() {
    AuthSessionStorage.resetCreds(AuthService.AUTH_TOKEN_KEY);
    AuthSessionStorage.resetCreds(AuthService.REFRESH_TOKEN_KEY);
    AuthSessionStorage.resetCreds(AuthService.ID_TOKEN_KEY);
  }

  static getAuthToken() {
    return AuthSessionStorage.getCreds(AuthService.AUTH_TOKEN_KEY);
  }

  static getRefreshToken() {
    return AuthSessionStorage.getCreds(AuthService.REFRESH_TOKEN_KEY);
  }

  static getIdToken() {
    return AuthSessionStorage.getCreds(AuthService.ID_TOKEN_KEY);
  }
}
