import {I18n} from 'i18n-js';

import EN from './en.json';
import AR from './ar.json';
import {Languages} from "@app/types/languages";

export const i18n = new I18n({
  ar: AR,
  en: EN,
});
const savedLang = localStorage.getItem('lang')
i18n.locale = !savedLang ? Languages.AR : savedLang;
