import React, { useContext } from 'react';
import Keycloak from 'keycloak-js';

import { AuthUser } from '@app/types/auth-user';

export interface IAuthContext {
  user: AuthUser;
  userRoles: Array<string>;
  idToken: string;
  authToken: string;
  refreshToken: string;
  authenticated: boolean;
  client: Keycloak;
  onLogout: () => void;
}

export const AuthContext = React.createContext({} as IAuthContext);

export const useAuth = () => useContext(AuthContext);
