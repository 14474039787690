import React, {FC} from 'react';
import {Avatar, AvatarProps, Tooltip} from "antd";

interface UserAvatarProps extends AvatarProps {
  title?: string;
  tooltip?: boolean;
  isCollapsed?: boolean;
  imgSrc?: string;
}

export const UserAvatar: FC<UserAvatarProps> = ({title, tooltip, isCollapsed, imgSrc, ...rest}) => {

  const getInitials = (name) => {
    if (!name) return '';
    const initials = name
      .split(' ')
      .map(word => word[0])
      .join('');

    return initials.toUpperCase();
  };

  return (
    <>
      {tooltip ? (
        <Tooltip placement={'left'} title={title} zIndex={isCollapsed ? 5 : -1}>
          <Avatar src={imgSrc ? rest.src : <div>{getInitials(title)}</div>} {...rest}/>
        </Tooltip>
      ) : (
        <Avatar src={imgSrc ? rest.src : <div>{getInitials(title)}</div>} {...rest}/>
      )}
    </>
  );
};
