import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Col, Row, Space, Typography} from "antd";
import {Content} from "antd/es/layout/layout";

import {i18n} from "@app/translations/i18n.config";
import {EmployeeTable} from "@app/components/tables/employee_table";
import {ReceiptsTable} from "@app/components/tables/receipts_table";
import {ActionButton} from "@app/components/action_button";
import {Comments} from "@app/components/comments";

import {Attachments} from "@app/components/attachments";
import {useMutation, useQuery} from "react-query";
import {getRefundRequest, updateRefundRequest} from "@app/api";
import {RQContent} from "@app/components/rq_content";
import {toast} from "react-toastify";

export const Request = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [receipts, setReceipts] = useState(null)

  const {status: requestStatus, data: requestData, refetch: refetchRequestData} = useQuery({
    queryKey: ['refund_request'],
    queryFn: () => getRefundRequest(id),
  });

  const {mutate: updateRequestMutate, status: updateRequestStatus} = useMutation({
    mutationFn: (data: any) => updateRefundRequest(id, data),
    onSuccess: async () => {
      refetchRequestData()
      toast.success('Changes was successfully saved');
    },
    onError: async (error) => {
      //TODO add toast message
      toast.error('Request error');
    }
  });

  const saveHandler = () => {
    updateRequestMutate({data: {receipts}})
  }

  useEffect(() => {
    if (requestData) {
      setReceipts(requestData?.request?.receipts)
    }
  }, [requestData])

  return (
    <Content className={'sub-content-white mx-2 px-4 py-2'}>
      <RQContent status={[requestStatus, updateRequestStatus]}>
        <>
          <Space className={'mb-4 w-100 justify-content-between sticky-top z-3 py-2 bg-white'}>
            <Typography.Title level={1}>{i18n.t('request.title')} {requestData?.requestId}</Typography.Title>
            <ActionButton
              //TODO add submit handler
              onSubmit={() => {
              }}
              onSave={saveHandler}
              onCancel={() => navigate('/')}
            />
          </Space>
          <Row gutter={16}>
            <Col span={18} className={'d-flex vstack align-items-start'}>
              <Space
                direction={'vertical'}
                size={'middle'}
                className={'w-100 light-grey-bg border-radius-large p-2'}
              >
                <EmployeeTable/>

                <ReceiptsTable
                  onChange={setReceipts}
                  data={!!receipts ? receipts : []}/>

              </Space>
              <Attachments/>
            </Col>
            <Col span={6}>
              <Comments/>
            </Col>
          </Row>
        </>
      </RQContent>
    </Content>
  );
};
