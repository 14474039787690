import React, {useState} from 'react';
import {ConfigProvider} from "antd";

import {Languages} from "@app/types/languages";

export const LanguageContext = React.createContext<{
  language: string;
  setLanguage: (language: string) => void;
  isRtl: boolean;
} | null>(null);

const GlobalProvider = ({children}: any) => {
  const [language, setLanguage] = useState(localStorage.getItem('lang'));

  const isRtl = language === Languages.AR;

  return (
    <LanguageContext.Provider key={language} value={{language, setLanguage, isRtl}}>
      <ConfigProvider direction={isRtl ? 'rtl' : 'ltr'}>
        {children}
      </ConfigProvider>
    </LanguageContext.Provider>
  );
};

export default GlobalProvider;
