import React, {forwardRef, useContext, useEffect, useState, useRef, useImperativeHandle} from 'react';
import {AutoField, AutoForm, SubmitField} from 'uniforms-antd';
import {Checkbox, Col, Row, Space} from 'antd';
import {toast} from 'react-toastify';
import {connectField} from 'uniforms';

import {initializeSchema} from '@app/schema';

import {LanguageContext} from '@app/global_provider';
import {FileUploaderField} from '@app/components/file_uploader/file-uploader-field';
import {FilesRepresentor} from '@app/components/file_uploader/files-representor';
import {i18n} from '@app/translations/i18n.config';

interface DynamicFormProps {
  template: any;
  hideModal?: () => void;
  onSubmit?: (data: any) => void;
  isPersisted?: boolean;
}

const UploadFilesField = connectField(FileUploaderField);
const RepresentFilesField = connectField(FilesRepresentor);

export const DynamicForm = forwardRef(({template, hideModal, onSubmit, isPersisted}: DynamicFormProps, ref) => {
  const {isRtl} = useContext<any>(LanguageContext);
  const formRef = useRef(null);

  const [schema, setSchema] = useState<any>(null);
  const [isAdditional, setIsAdditional] = useState<boolean>(true);

  useEffect(() => {
    if (template) {
      try {
        const bridge = initializeSchema(template);
        setSchema(bridge);
      } catch (error) {
        toast.error(i18n.t('messages.invalidTemplate'));
      }
    }
  }, [template]);

  const handleSubmitForm = async (model: any) => {
    onSubmit(model)
    formRef.current.reset();

    if (!isAdditional) {
      hideModal();
    }
  };

  useImperativeHandle(ref, () => ({
    resetForm: () => {
      if (formRef.current) {
        formRef.current.reset();
      }
    }
  }));

  const renderFormFields = (properties: any) => {
    return Object.keys(properties).map((key) => {
      const property = properties[key];
      const propertyType = property?.uniforms?.type || property?.type;

      switch (propertyType) {
        case 'file':
          const FieldComponent = isPersisted ? RepresentFilesField : UploadFilesField;
          return <FieldComponent key={key} name={key}/>;

        default:
          return <AutoField min={0} key={key} name={key}/>;
      }
    });
  };


  if (!schema) {
    return null;
  }

  return (
    <Row>
      <Col className={`p-3 ${isRtl && 'form-right-align'}`} span={24}>
        <AutoForm
          placeholder
          validate="onChangeAfterSubmit"
          schema={schema}
          onSubmit={handleSubmitForm}
          ref={formRef}
        >
          {renderFormFields(schema?.schema?.properties)}

          <Space className={'w-100 justify-content-between mt-5'}>
            <Checkbox
              defaultChecked
              checked={isAdditional}
              onChange={(e) => setIsAdditional(e.target.checked)}
            >
              {i18n.t('invoices.additionalInvoiceText')}
            </Checkbox>
            <SubmitField value={i18n.t('invoices.add')} onSubmit={handleSubmitForm} disabled={false}/>
          </Space>

        </AutoForm>
      </Col>
    </Row>
  );
});
