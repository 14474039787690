import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';

import { Router } from '@app/router';
import GlobalProvider from '@app/global_provider';
import { AuthProvider } from '@app/auth/auth-provider';
import ReactQueryConfiguration from '@app/config/api/react-query-configuration';

export function App() {
  return (
    <div className="App">
      <ReactQueryConfiguration>
        <AuthProvider>
          <GlobalProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </GlobalProvider>
        </AuthProvider>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ReactQueryConfiguration>
    </div>
  );
}
