import React from 'react';
import { AuthRouter } from '@app/router/auth_router';
import { UnAuthRouter } from '@app/router/unauth_router';
import { useAuth } from '@app/auth/auth-context';

export function Router() {
  const { authenticated } = useAuth();

  return authenticated ? <AuthRouter /> : <UnAuthRouter />;
}
