import React, {FC, useState} from 'react';
import {Button, Popconfirm, Space, Table, Typography} from 'antd';
import dayjs from 'dayjs';
import {RQContent} from '@app/components/rq_content';
import {Link} from "react-router-dom";
import {useMutation, useQuery} from "react-query";

import {i18n} from "@app/translations/i18n.config";

import {ReactComponent as EditIcon} from '@app/assets/icons/edit.svg';
import {RequestStatus} from "@app/components/request_status";
import {RequestStatuses} from "@app/types/statuses";
import {deleteRefundRequest, getRefundRequests} from "@app/api";
import {ReactComponent as DeleteIcon} from "@app/assets/icons/delete.svg";
import {toast} from "react-toastify";
import {calculateTotalByField} from "@app/utils/calculations/calculations";

interface RequestTableProps {
  isDrafts?: boolean;
}

export const RequestTable: FC<RequestTableProps> = ({isDrafts}) => {

  const [draftRequests, setDraftRequests] = useState([])
  const [requests, setRequests] = useState([])

  const {status, refetch} = useQuery({
    queryKey: ['refund_requests'],
    queryFn: () => getRefundRequests(),
    onSuccess: (data) => {
      setDraftRequests(data.filter(request => request.status.name === RequestStatuses.Draft))
      setRequests(data.filter(request => request.status.name !== RequestStatuses.Draft))
    },
  });

  const {mutate: deleteRequestMutate, isLoading: isDeleteLoading} = useMutation({
    mutationFn: (data: string) => deleteRefundRequest(data),
    onSuccess: async () => {
      refetch()
      toast.success('Request was successfully deleted');
    },
    onError: async (error) => {
      //TODO add toast message
      toast.error('Request error');
    }
  });

  const columns = [
    {
      title: i18n.t('request.tableTitle.requestId'),
      dataIndex: 'requestId',
      key: 'requestId',
    },
    {
      title: i18n.t('request.tableTitle.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: () => dayjs(new Date()).format('MM/DD/YYYY'),
    },
    {
      title: i18n.t('request.tableTitle.clause'),
      dataIndex: '',
      key: '',
      render: () => '---',
    },
    {
      title: i18n.t('request.tableTitle.administration'),
      dataIndex: '',
      key: '',
      render: () => '---',
    },
    {
      title: i18n.t('request.tableTitle.fullAmount'),
      render: (record) => record.request.receipts ? calculateTotalByField(record.request.receipts, 'amount') : '---',
    },
    {
      title: i18n.t('request.tableTitle.status'),
      render: (record) => <RequestStatus status={record?.status?.name}/>,
    },
    {
      title: i18n.t('request.tableTitle.recipient'),
      dataIndex: 'recipientId',
      key: 'recipientId',
    },
    {
      width: 5,
      render: (record) =>
        <Space size={'large'}>
          {isDrafts && (
            <Popconfirm
              title={
                <>
                  <Typography.Title level={4}>{i18n.t('popconfirm.deleteRequestTitle')}</Typography.Title>
                  <p>{i18n.t('popconfirm.deleteRequestDescription')}</p>
                </>
              }
              icon={null}
              okText={i18n.t('popconfirm.yes')}
              cancelText={i18n.t('popconfirm.no')}
              placement={'top'}
              onConfirm={() => deleteRequestMutate(record.id)}
            >
              <Button type={'dashed'} size={'small'} className={'collapsed'} icon={<DeleteIcon/>}/>
            </Popconfirm>
          )}
          <Link className={'d-flex align-items-center'} to={`/request/${record.id}`}><EditIcon/></Link>
        </Space>,
    },
  ];

  return (
    <RQContent status={status}>
      <Table
        loading={isDeleteLoading}
        dataSource={isDrafts ? draftRequests : requests}
        pagination={{size: 'small', hideOnSinglePage: true}}
        columns={columns}
      />
    </RQContent>
  );
};
