import React, {FC, useEffect, useState} from 'react';
import {Badge, Button, Card, Empty, Popconfirm, Space, Table, Typography} from "antd";
import {NumericFormat} from "react-number-format";

import {i18n} from "@app/translations/i18n.config";
import {RQContent} from "@app/components/rq_content";

import {ReactComponent as PlusIcon} from "@app/assets/icons/plus.svg";
import {ReactComponent as SearchIcon} from "@app/assets/icons/search.svg";
import {ReactComponent as DeleteIcon} from "@app/assets/icons/delete.svg";
import {ReceiptModal} from "@app/components/modals/receipt_modal";
import {calculateTotalByField} from "@app/utils/calculations/calculations";

interface ReceiptsTableProps {
  onChange: (receipts: any) => void;
  data: Array<any>;
}

export const ReceiptsTable: FC<ReceiptsTableProps> = ({onChange, data}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = (item) => {
    const updatedInvoices = data.filter((invoice) => invoice !== item);
    onChange(updatedInvoices);
  };

  const columns = [
    {
      width: 5,
      render: (text, record, index) => index + 1,
    },
    {
      title: i18n.t('invoices.tableTitle.invoice'),
      dataIndex: 'receiptNumber',
      key: 'receiptNumber',
      render: (text) => text ? text : '---',
    },
    {
      title: i18n.t('invoices.tableTitle.company'),
      dataIndex: 'company',
      key: 'company',
      render: (text) => text ? text : '---',
    },
    {
      title: <Badge dot={true} status="default" text={i18n.t('invoices.tableTitle.amount')}/>,
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => text ? text : '---',
    },
    {
      title: <Badge dot={true} status="warning" text={i18n.t('invoices.tableTitle.tax')}/>,
      dataIndex: 'tax',
      key: 'tax',
      render: (text) => text ? text : '---',
    },
    {
      title: <Badge dot={true} status="success" text={i18n.t('invoices.tableTitle.totalAmount')}/>,
      dataIndex: 'taxedAmount',
      key: 'taxedAmount',
      render: (text) => text ? text : '---',
    },
    {
      title: i18n.t('invoices.tableTitle.purchaseDescription'),
      dataIndex: 'description',
      key: 'description',
      render: (text) => text ? text : '---',
    },
    {
      title: i18n.t('invoices.tableTitle.clause'),
      dataIndex: 'type',
      key: 'type',
      render: (text) => text ? text : '---',
    },
    {
      width: 70,
      render: (record) =>
        <Popconfirm
          title={
            <>
              <Typography.Title level={4}>{i18n.t('popconfirm.deleteInvoiceTitle')}</Typography.Title>
              <p>{i18n.t('popconfirm.deleteInvoiceDescription')}</p>
            </>
          }
          icon={null}
          okText={i18n.t('popconfirm.yes')}
          cancelText={i18n.t('popconfirm.no')}
          placement={'top'}
          onConfirm={() => handleDelete(record)}
        >
          <Button type={'dashed'} size={'small'} className={'collapsed'} icon={<DeleteIcon/>}/>
        </Popconfirm>,
    }
  ];

  return (
    <Card
      bordered={false}
      title={
        <Space size={null} className={'w-100 justify-content-between'}>
          <Typography.Title level={3}>
            {i18n.t('invoices.title')}
          </Typography.Title>
          {data?.length > 0 && (
            <Space>
              <Badge
                className={'inline success'}
                count={
                  <NumericFormat
                    displayType={'text'}
                    value={calculateTotalByField(data, 'taxedAmount')}
                    thousandSeparator=","/>
                }
                text={i18n.t('invoices.tableTitle.totalAmount')}/>
              <Badge
                className={'inline warning'}
                count={
                  <NumericFormat
                    displayType={'text'}
                    value={calculateTotalByField(data, 'tax')}
                    thousandSeparator=","/>
                }
                showZero
                text={i18n.t('invoices.tableTitle.tax')}/>
              <Badge
                className={'inline default'}
                count={
                  <NumericFormat
                    displayType={'text'}
                    value={calculateTotalByField(data, 'amount')}
                    thousandSeparator=","/>
                }
                text={i18n.t('invoices.tableTitle.amount')}/>
            </Space>
          )}
          <Button
            icon={<PlusIcon/>}
            size={'small'}
            type={'default'}
            onClick={() => setIsModalOpen(true)}
          >
            {i18n.t('invoices.new')}
          </Button>
        </Space>
      }
    >
      {data?.length < 1 || !data ? (
        <Space className={'w-100 justify-content-center p-5'}>
          <Empty
            className={'light-grey-bg p-5'}
            image={<SearchIcon/>}
            description={
              <>
                <p>{i18n.t('invoices.emptyText')}</p>
                <Button
                  className={'m-auto'}
                  icon={<PlusIcon/>}
                  size={'small'}
                  type={'default'}
                  onClick={() => setIsModalOpen(true)}
                >
                  {i18n.t('invoices.new')}
                </Button>
              </>
            }
          />
        </Space>
      ) : (
        <RQContent status={"success"}>
          <Table
            pagination={{size: 'small', hideOnSinglePage: true, defaultPageSize: 5}}
            dataSource={data}
            columns={columns}
          />
        </RQContent>
      )}
      <ReceiptModal
        onInvoiceAdded={(invoice) => onChange([...data, invoice])}
        open={isModalOpen}
        footer={false}
        onCancel={() => setIsModalOpen(false)}
      />
    </Card>
  );
};
