import { Button, Upload } from 'antd';
import React, { FC } from 'react';
import { HTMLFieldProps } from 'uniforms';

type FieldType = {
  name: string;
  field: any;
};
type FileUploaderProps = HTMLFieldProps<string, HTMLDivElement> & FieldType;

export const FileUploaderField: FC<FileUploaderProps> = ({ onChange, name, field }) => {
  const uploadHandler = async (values: any) => {
    const { fileList }: { file: File; fileList: File[] } = values;

    const readAsDataURLAsync = (fileObject: any) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const content = event.target.result;

          resolve({
            name: fileObject.name,
            type: fileObject.type,
            size: fileObject.size,
            content,
          });
        };
        reader.readAsDataURL(fileObject.originFileObj);
      });
    };

    let base64fileArray = await Promise.all(fileList.map(async (file: File) => await readAsDataURLAsync(file)));

    if (base64fileArray) {
      onChange(JSON.stringify(base64fileArray), name);
    }
  };
  return (
    <Upload
      beforeUpload={() => false}
      multiple={field?.uniforms?.multiple || false}
      maxCount={5}
      onChange={uploadHandler}
    >
      <Button className={'mb-3'}>Upload files</Button>
    </Upload>
  );
};
