import React from 'react';
import {Table} from "antd";

import {i18n} from "@app/translations/i18n.config";
import {RQContent} from "@app/components/rq_content";
import {useAuth} from "@app/auth/auth-context";

export const EmployeeTable = () => {
  const {user} = useAuth();

  const columns = [
    {
      title: i18n.t('employee.tableTitle.userName'),
      render: () => user.name,
    },
    {
      title: i18n.t('employee.tableTitle.administration'),
      render: () => user.name,
    },
    {
      title: i18n.t('employee.tableTitle.ITDepartment'),
      render: () => user.name,
    },
  ];

  return (
    <RQContent status={"success"}>
      <Table
        rowClassName={'without-bg'}
        dataSource={[{}]}
        pagination={false}
        columns={columns}
      />
    </RQContent>
  );
};
