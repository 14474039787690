import React from 'react';

import {ReactComponent as LogoIcon} from '@app/assets/icons/refund_logo.svg';
import {ReactComponent as LogoTextIcon} from '@app/assets/icons/logo_text.svg';
import {ReactComponent as LogoTextARIcon} from '@app/assets/icons/logo_text_ar.svg';
import {LanguageContext} from '@app/global_provider';

export const Logo = ({isCollapsed}) => {
  const {isRtl} = React.useContext<any>(LanguageContext);

  return (
    <div className={'d-flex align-items-start mb-5'}>
      <LogoIcon/>
      {!isCollapsed && (isRtl ? <LogoTextARIcon className={'mx-2'}/> : <LogoTextIcon className={'mx-2'}/>)}
    </div>
  );
};
