import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {Dashboard} from '@app/pages/dashboard';
import {AuthLayout} from '@app/components/layout';
import {Request} from "@app/pages/request";

export function AuthRouter() {
  return (
    <AuthLayout>
      <Routes>
        <Route path="/" index element={<Dashboard/>}/>
        <Route path="/request/:id" element={<Request/>}/>
        <Route path="*" element={<Navigate to="/"/>}/>
      </Routes>
    </AuthLayout>
  );
}
